import * as React from 'react'
import Seo from '../components/seo'
import PhotoCentricLayout from '../layouts/photoCentricLayout'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const BioParagraph = styled.p`
  font-size: 1.1em;
  text-align: center;
  padding: 1em;
`

const Flex = styled.div`
  display: flex;
`

const Spacer = styled.div`
  flex: 1 0 0;
`

const FlexContent = styled.div`
  flex: 1 1 50%;
`

const AboutPage = () => {
  const image = <StaticImage width={300} placeholder="blurred" src="../images/michael-swan.png" alt="Michael Swan" />
  return (
    <PhotoCentricLayout pageTitle={ "About the Artist" } image={ image }>
      <Flex>
        <Spacer/>
        <FlexContent>
          <BioParagraph>
            I make paintings and drawings.
            Through these media, I seek to utilize various color harmonies and application techniques to visually engage the audience. It is also a way for me to experiment with spontaneity and chance and use it as an opportunity to develop my own unique artistic voice.
          </BioParagraph>
          <BioParagraph>
            I try not to present overly-complex theories or ideas, I believe that a painting should be first and foremost, a painting.
          </BioParagraph>
          <BioParagraph>
            I currently live and work in Latrobe, Pennsylvania.
          </BioParagraph>
        </FlexContent>
        <Spacer/>
      </Flex>
    </PhotoCentricLayout>
  )
}

export const Head = () => <Seo title="About the Artist" />

export default AboutPage
